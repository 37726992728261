import React from 'react';
import VendorSideBar from '../Components/VendorSideBar';
import './CreateEvent.css'
import { Link } from 'react-router-dom';
import { BiPlus } from 'react-icons/bi';
import NavigationBar from '../Components/NavigationBar';
import Image1 from '../../Assets/Screenshot1.jpg'
import Image2 from '../../Assets/Screenshot2.jpg'
import Image3 from '../../Assets/Screenshot3.jpg'

const CreateEvent = () => {
    return (
        <>
         <NavigationBar />
            <div className="container-fluid">
                <div className="row">
                    <VendorSideBar />
                    <div className="col-lg-10 col-md-10 col-12">                        
                    <div className="text-center" id='displayForLargeDevice'>
                        <p className="vender-heading text-start mb-3 fw-semibold border-bottom border-primary">
                            Create your event in 3 easy steps
                        </p>
                        <div className="content-body">
                            <div className="card mb-4 shadow-style" style={{ border: "none" }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6">
                                            <div className="set-padding" style={{ background: "#587EF9" }}>
                                                <div className="screenshort">
                                                    <img src={Image2} className="w-100 h-100" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <div style={{ marginTop: "-4rem", marginBottom: "-4rem" }}>
                                                <span className="step1"> 1 </span>
                                                <div className="steps-content" style={{ marginTop: "-5rem" }}>
                                                    <span>One time account creation process</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-4 shadow-style" style={{ border: "none" }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6">
                                            <div className="set-padding" style={{ background: "#F41E6D" }}>
                                                <div className="screenshort">
                                                    <img src={Image1} className="w-100 h-100" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <div style={{ marginTop: "-4rem", marginBottom: "-4rem" }}>
                                                <span className="step2"> 2 </span>
                                                <div className="steps-content" style={{ marginTop: "-5rem" }}>
                                                    <span>Create your event within minutes.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-4 shadow-style" style={{ border: "none" }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6">
                                            <div className="set-padding" style={{ background: "#FDECA0" }}>
                                                <div className="screenshort">
                                                    <img src={Image3} className="w-100 h-100" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <div style={{ marginTop: "-4rem", marginBottom: "-4rem" }}>
                                                <span className="step3"> 3 </span>
                                                <div className="steps-content" style={{ marginTop: "-5rem" }}>
                                                    <span>Publish your event and start selling tickets</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-4">
                            <Link to='/details' className='link create-event-btn text-center w-100'>
                                <span> <BiPlus size={15} /> Create Event</span>
                            </Link>
                            </div>
                            <p className="sub-head2">
                                How much does it cost to sell tickets
                            </p>
                            <div className="card mb-3 shadow-style" style={{ border: "none" }}>
                                <div className="card-body">
                                    <span className="sub-head2-content">
                                    Paid tickets are charged at 4% + 2% payment on our starter package.
                                    </span>
                                </div>
                            </div>
                            <p className="sub-head2">
                                Can I sell free tickets
                            </p>
                            <div className="card mb-3 shadow-style" style={{ border: "none" }}>
                                <div className="card-body">
                                    <span className="sub-head2-content">
                                    You can sell free tickets and ticketing tools are free of charge.
                                    </span>
                                </div>
                            </div>
                            <p className="sub-head2">
                                What kind of event can I sell tickets for?
                            </p>
                            <div className="card mb-3 shadow-style" style={{ border: "none" }}>
                                <div className="card-body">
                                    <span className="sub-head2-content">
                                    You can sell tickets for any kind of event: festivals, comedy nights, screenings, gigs, workshops, clubnights, community fairs, business conferences, yoga classes, charity fundraisers, virtual events, local football matches and more.
                                    </span>
                                </div>
                            </div>
                            <p className="sub-head2">
                                What other features are there?
                            </p>
                            <div className="card mb-3 shadow-style" style={{ border: "none" }}>
                                <div className="card-body text-start">
                                    <span className="sub-head2-content">
                                        There are plenty to choose from, here are just a few:
                                        <ul>
                                        <li>A simple and customisable user journey with multiple payment methods</li>
                                            <li>Flexible ticket types including tiered ticketing</li>
                                            <li>Reward points for your fans</li>
                                            <li>Scan tickets using your smartphone with the free Box Office web-app</li>
                                            <li>View real-time reports of all your sales</li>
                                            <li>Get access to your data</li>
                                            <li>Best in kind dashboard</li>
                                            <li>Visit a comprehensive support desk to help you along your ticketing journey</li>
                                            <li>Get access to your data</li>
                                            <li>24x7 customer support</li>
                                        </ul>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="text-center py-3" id='displayForSmallDevice'>
                        <p className="sub-head mb-3 fw-semibold">
                            Create your event in 3 easy steps
                        </p>
                        <div className="content-body">
                            <div className="row shadow-style">
                                <div className="col-12">
                                    <div style={{ marginTop: "-4rem", marginBottom: "1rem" }}>
                                        <span className="step1"> 1 </span>
                                        <div className="steps-content" style={{ marginTop: "-5rem" }}>
                                            <span>One time account creation process</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="set-padding" style={{ background: "#587EF9" }}>
                                        <div className="screenshort">
                                            <img src={Image2} className="w-100 h-100" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="row shadow-style">
                                <div className="col-12">
                                    <div style={{ marginTop: "-4rem", marginBottom: "1rem" }}>
                                        <span className="step2"> 2 </span>
                                        <div className="steps-content" style={{ marginTop: "-5rem" }}>
                                            <span>Create your event within minutes.</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="set-padding" style={{ background: "#F41E6D" }}>
                                        <div className="screenshort">
                                            <img src={Image1} className="w-100 h-100" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="row shadow-style">
                                <div className="col-12">
                                    <div style={{ marginTop: "-4rem", marginBottom: "1rem" }}>
                                        <span className="step3"> 3 </span>
                                        <div className="steps-content" style={{ marginTop: "-5rem" }}>
                                            <span>Publish your event and start selling tickets</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="set-padding" style={{ background: "#FDECA0" }}>
                                        <div className="screenshort">
                                            <img src={Image3} className="w-100 h-100" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="mb-5">
                            <Link to='/details' className='create-event-btn text-center'>
                                <span> <BiPlus size={15} /> Create Event</span>
                            </Link>
                            </div>
                            <p className="sub-head2">
                                How much does it cost to sell tickets
                            </p>
                            <div className="card mb-5 shadow-style" style={{ border: "none" }}>
                                <div className="card-body">
                                    <span className="sub-head2-content">
                                    Paid tickets are charged at 4% + 2% payment on our starter package.
                                    </span>
                                </div>
                            </div>
                            <p className="sub-head2">
                                Can I sell free tickets
                            </p>
                            <div className="card mb-5 shadow-style" style={{ border: "none" }}>
                                <div className="card-body">
                                    <span className="sub-head2-content">
                                    You can sell free tickets and ticketing tools are free of charge.
                                    </span>
                                </div>
                            </div>
                            <p className="sub-head2">
                                What kind of event can I sell tickets for?
                            </p>
                            <div className="card mb-5 shadow-style" style={{ border: "none" }}>
                                <div className="card-body">
                                    <span className="sub-head2-content">
                                    You can sell tickets for any kind of event: festivals, comedy nights, screenings, gigs, workshops, clubnights, community fairs, business conferences, yoga classes, charity fundraisers, virtual events, local football matches and more.
                                    </span>
                                </div>
                            </div>
                            <p className="sub-head2">
                                What other features are there?
                            </p>
                            <div className="card mb-5 shadow-style" style={{ border: "none" }}>
                                <div className="card-body text-start">
                                    <span className="sub-head2-content">
                                        There are plenty to choose from, here are just a few:
                                        <ul>
                                        <li>A simple and customisable user journey with multiple payment methods</li>
                                            <li>Flexible ticket types including tiered ticketing</li>
                                            <li>Reward points for your fans</li>
                                            <li>Scan tickets using your smartphone with the free Box Office web-app</li>
                                            <li>View real-time reports of all your sales</li>
                                            <li>Get access to your data</li>
                                            <li>Best in kind dashboard</li>
                                            <li>Visit a comprehensive support desk to help you along your ticketing journey</li>
                                            <li>Get access to your data</li>
                                            <li>24x7 customer support</li>
                                        </ul>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default CreateEvent;